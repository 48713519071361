<script setup lang="ts">
    import type { IntroData, Asset, HighlightData, BackgroundColorsPredefined } from '~/@types/cms';

    /**
     * renders an optional intro
     * optional defined background color (light=white, black=black, grey=lightgray, mossgreen=the woom mossgreen color)
     * optional watermark is in the background as overlay
     * the Highlight data is rendered in the org-highlight component
     * on desktop, the direction of every 2nd highlight should be in reverse
     */
    export interface HighlightsContainerProps {
        list: Array<HighlightData>;
        intro?: IntroData;
        watermark?: Asset;
        background: BackgroundColorsPredefined;
    }

    const props = withDefaults(defineProps<HighlightsContainerProps>(), {
        background: 'default',
    });

    const badges = computed(() => {
        const badgesSet = new Set(props.list.map((b) => b.product_badge?.product_badge).filter(isDefined));
        return Array.from(badgesSet);
    });
</script>
<template>
    <mol-section
        :intro="intro"
        :watermark="watermark"
        :background="background"
        class="org-highlights-container">
        <div
            v-if="badges.length"
            class="col-span-2 space-y-2 md:col-span-12">
            <template v-for="badge in badges">
                <lazy-atm-badge-intro
                    v-if="badge !== 'none'"
                    :badge="badge" />
            </template>
        </div>
        <div class="col-span-2 md:col-span-12">
            <template
                v-for="(item, index) in list"
                :key="item.id">
                <mol-highlight
                    :background="background"
                    :hero="item.hero"
                    :title="item.title"
                    :content="item.copy"
                    :link="item.link || undefined"
                    :product-badge="item.product_badge"
                    :odd="index % 2 !== 0" />
            </template>
        </div>
    </mol-section>
</template>
